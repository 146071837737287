import { AutomatitCarousel } from './automatit_carousel.m';

const homeAboutCaro = {
	caro: document.querySelector('#home_about_caro'),
	init() {
		window.facImgsCaro = AutomatitCarousel({
			element: this.caro,
			images: [
				{src: "/dist/images/slide_502_storage.jpg", alt: ""},
				{src: "/dist/images/slide_brand_new_502.jpg", alt: ""},
				{src: "/dist/images/slide_grand_opening.jpg", alt: ""},
				{src: "/dist/images/slide_mud_lane_street.jpg", alt: ""},
				{src: "/dist/images/slide_mud_lane_front.jpg", alt: ""},
				{src: "/dist/images/slide_drive_up.jpg", alt: ""},
				{src: "/dist/images/slide_climate_control_hallway.jpg", alt: ""},
				{src: "/dist/images//slide_lobby.jpg", alt: ""},
				{src: "/dist/images/slide_keypad.jpg", alt: ""},
				{src: "/dist/images/slide_exterior_units.jpg", alt: ""},
			],
			imagesAsBackgrounds: true,
			useChevrons: false,
			showDots: true,
		});
	}
};

const homeSizeCaro = {
	caro: document.querySelector('#size_carousel'),
	init() {
		window.sizeCaro = AutomatitCarousel({
			element: this.caro,
			intervalTiming: 0,
			useChevrons: false,
		});
	}
};

export const initHomeAboutCaro = () => homeAboutCaro.init();
export const initHomeSizeCaro = () => homeSizeCaro.init();