import { initHomeAboutCaro, initHomeSizeCaro } from './home.js';
import { initMap } from './map.js';
import { initMobileHeader } from './header.js';
import { contactSubmit } from './contact.js';
import { facilityTop, facilityCaro, facFilters, facRowClick } from './facility'; 

if (document.getElementById('home_about_caro')) {
	initHomeAboutCaro();
}

if (document.getElementById('map')) {
	initMap();
}

if (document.getElementById('size_carousel')) {
	initHomeSizeCaro();
}

initMobileHeader();

if (document.getElementById('contact_form')) {
	contactSubmit();
}

if(document.querySelector('#toggle_fac_info')) {
	facilityTop();
}

if(document.querySelector('#facility_caro')) {
	facilityCaro();
}

if(document.querySelector('#facility_filters')) {
	facFilters();
}
if(document.querySelector('#rates_table')) {
	facRowClick();
}