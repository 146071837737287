import { AutomatitCarousel } from './automatit_carousel.m';

if (!Element.prototype.matches) {
	Element.prototype.matches =
	Element.prototype.msMatchesSelector ||
	Element.prototype.webkitMatchesSelector;
}
  
if (!Element.prototype.closest) {
	Element.prototype.closest = function(s) {
		var el = this;
  
		do {
			if (Element.prototype.matches.call(el, s)) return el;
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);
		return null;
	};
}

export const facilityTop = () => {
	const mobileToggle = document.querySelector('#toggle_fac_info');
	const info = document.querySelector('#facility_info_container');
	function mobileClick(){
		info.classList.toggle('active');
		mobileToggle.classList.toggle('active');
	}
	mobileToggle.addEventListener('click', () => mobileClick());
};

export const facilityCaro = () => {
	AutomatitCarousel({
		element: document.querySelector('#facility_caro'),
		imagesAsBackgrounds: true,
		images: window.facCaroImgs
	});
};

export const facFilters = () => {
	const filters = document.querySelectorAll('#facility_filter_sizes button');
	const rows = document.querySelectorAll('.rates_row');
	const noUnits = document.querySelector('#no_units');

	document.querySelector('#facility_filter_sizes').addEventListener('click', e => {
		const clickedFilter = e.target.closest('button');
		var unitShown = 0;
		if(clickedFilter) {
			filters.forEach(i => {
				i.classList.remove('active');
			});
			if(clickedFilter.classList.contains('filter')) {
				clickedFilter.classList.toggle('active');
			}
			rows.forEach(i => {
				i.setAttribute('hide', true);
				if((i.getAttribute('size') === clickedFilter.getAttribute('size') || clickedFilter.getAttribute('size') === 'all')) {
					unitShown++;
					i.removeAttribute('hide');
				}
			});
			if(unitShown === 0){
				noUnits.style.display = 'block';
			}else {
				noUnits.style.display = 'none';
			}
		}
	});
};

export const facRowClick = () => {
	if(window.innerWidth < 750) {
		const rows = document.querySelectorAll('.rates_row');
		rows.forEach(
			i => {
				i.addEventListener('click', function(e){
					location.href = e.currentTarget.querySelector('a').href;
				});
			}
		);
	}
};